export const environment = {
  production: true,
  apiAuthUrl: 'https://bgauth.sengup.com/api',
  apiAdminUrl: 'https://admin.sengup.com/api',
  apiReseauUrl: 'https://reseau.sengup.com/api',
  apiTransfertUrl: 'https://services.sengup.com/transfert/api',
  walletApi: 'https://services.sengup.com/wallet/api',
  apiAuthUrlMobile: 'https://bgauth.sengup.com/api'

};
